/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {

  value = '';
  length = 25;

  @Input() maxLength: number;
  @Output() inputText = new EventEmitter();
  @Output() onEnter = new EventEmitter();
  @Output() clearData = new EventEmitter();
  @Input() set query(value) {
      this.value = value;
  }

  constructor() { }

  ngOnInit() {
    if (this.maxLength === 42) {
      this.length = 42;
    }
  }

  clear() {
    this.value = '';
    this.inputText.emit('');
    this.clearData.emit('');
  }

}
