import { Component, Input, OnInit } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-percent-change',
  templateUrl: './percent-change.component.html',
  styleUrls: ['./percent-change.component.scss'],
})
export class PercentChangeComponent implements OnInit {

  @Input() percentChange: number;
  @Input() time: string;

  constructor(
    private decimalPipe: DecimalPipe
  ) { }

  ngOnInit() {}

  getArrow() {
    let positive = '&#9650; ' + this.percentChange + '%';
    let negative = '&#9660; ' + this.percentChange + '%';
    let neutral = ' ' + this.decimalPipe.transform(this.percentChange, '1.2-2') + '%';
    if (this.time) {
      positive = this.time + ' ' + positive;
      negative = this.time + ' ' + negative;
      neutral = this.time + ' ' + neutral;
    }
    if (this.percentChange > 0) {
      return positive;
    } else if (this.percentChange < 0) {
      return negative;
    } else {
      return neutral;
    }
  }

  checkPercent() {
    if (this.percentChange > 0) {
      return 'success';
    } else if (this.percentChange < 0) {
      return 'warning';
    } else {
      return 'neutral';
    }
  }
}
