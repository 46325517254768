import { Component, OnInit } from '@angular/core';
import { AppStoreService } from '../services/app-store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pre-access',
  templateUrl: './pre-access.component.html',
  styleUrls: ['./pre-access.component.scss']
})
export class PreAccessComponent implements OnInit {

  clickCount = 0;
  username= '';
  password= '';
  passwordType: 'text' | 'password' = 'password';
  passwordTypeBoolean = true;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  signin() {
    if(this.clickCount > 5 && this.username === 'mightydemo' && this.password === 'willie123') {
      localStorage.setItem('beta', JSON.stringify(true));
      this.router.navigate(['/auth']);
    }
  }

  click(){
    this.clickCount++;
  }

}
