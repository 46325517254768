import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// Models
import { LanguageOption } from '../models/translate/language-option';
import { TranslateService } from '@ngx-translate/core';


declare var zE: any;

@Injectable({
  providedIn: 'root'
})
export class ForTranslateService {

  _languages: LanguageOption[] = [
    {
      language: 'en',
      locale: 'us',
      text: 'English',
      image: './assets/images/flags/united-states-of-america-flag-xs.png',
      currency: 'dollar',
      symbol: '$',
      currencyCode: 'USD',
      placement: 'before'
    },
    {
      language: 'cn',
      locale: 'zh',
      text: '中文',
      image: './assets/images/flags/china-flag-xs.png',
      currency: 'renminbi',
      symbol: 'CN¥',
      currencyCode: 'CNY',
      placement: 'before'
    },
    {
      language: 'tw',
      locale: 'zh_TW',
      text: '繁體中文',
      image: './assets/images/flags/hongkong-flag-xs.png',
      currency: 'new-taiwan-dollar',
      symbol: 'NT$',
      currencyCode: 'TWD',
      placement: 'before'
    },
    // {
    //   language: 'vi',
    //   locale: 'vi_VN',
    //   text: 'Tiếng Việt',
    //   image: './assets/images/flags/vietnam-flag-xs.png',
    //   currency: 'dong',
    //   symbol: '₫',
    //   currencyCode: 'VND',
    //   placement: 'after'
    // },
    // {
    //   language: 'ja',
    //   locale: 'ja_JP',
    //   text: '日本語',
    //   image: './assets/images/flags/japan-flag-xs.png',
    //   currency: 'yen',
    //   symbol: 'JP¥',
    //   currencyCode: 'JPY',
    //   placement: 'before'
    // },
    // {
    //   language: 'ko',
    //   locale: 'ko_KR',
    //   text: '한국어',
    //   image: './assets/images/flags/south-korea-flag-xs.png',
    //   currency: 'won',
    //   symbol: '₩',
    //   currencyCode: 'KRW',
    //   placement: 'before'
    // },
    // {
    //   language: 'ru',
    //   locale: 'ru',
    //   text: 'русский язык',
    //   image: './assets/images/flags/russia-flag-xs.png',
    //   currency: 'ruble',
    //   symbol: '₽',
    //   currencyCode: 'RUB',
    //   placement: 'after'
    // },
    // {
    //   language: 'th',
    //   locale: 'th',
    //   text: 'ไทย',
    //   image: './assets/images/flags/thailand-flag-xs.png',
    //   currency: 'baht',
    //   symbol: '฿',
    //   currencyCode: 'THB',
    //   placement: 'before'
    // },
    // {
    //   language: 'es',
    //   locale: 'mx',
    //   text: 'Español',
    //   image: './assets/images/flags/mexico-flag-xs.png',
    //   currency: 'peso',
    //   symbol: '$',
    //   currencyCode: 'MXN',
    //   placement: 'before'
    // },
    // {
    //   language: 'de',
    //   text: 'Deutsch',
    //   image: './assets/images/flags/germany-flag-xs.png'
    // },
    // {
    //   language: 'fr',
    //   text: 'français',
    //   image: './assets/images/flags/france-flag-xs.png'
    // }
  ];

  public languageFlagMap = {
    en: './assets/images/flags/united-states-of-america-flag-xs.png',
    cn: './assets/images/flags/china-flag-xs.png',
    gb: './assets/images/flags/united-kingdom-flag-xs.png',
    cl: './assets/images/flags/chile-flag-xs.png',
    zh: './assets/images/flags/china-flag-xs.png',
    hk: './assets/images/flags/hongkong-flag-xs.png',
    tw: './assets/images/flags/taiwan.svg',
    kh: './assets/images/flags/cambodia-flag-xs.png',
    vi: './assets/images/flags/vietnam-flag-xs.png',
    vn: './assets/images/flags/vietnam-flag-xs.png',
    vt: './assets/images/flags/vietnam-flag-xs.png',
    uk: './assets/images/flags/united-kingdom-flag-xs.png',
    ja: './assets/images/flags/japan-flag-xs.png',
    jp: './assets/images/flags/japan-flag-xs.png',
    kz: './assets/images/flags/kazakhstan-flag-xs.png',
    kr: './assets/images/flags/south-korea-flag-xs.png',
    ko: './assets/images/flags/south-korea-flag-xs.png',
    my: './assets/images/flags/malaysia-flag-xs.png',
    mm: './assets/images/flags/myanmar-flag-xs.png',
    in: './assets/images/flags/india-flag-xs.png',
    sg: './assets/images/flags/singapore-flag-xs.png',
    hu: './assets/images/flags/hungary-flag-xs.png',
    mo: './assets/images/flags/macau-flag-xs.png',
    ru: './assets/images/flags/russia-flag-xs.png',
    th: './assets/images/flags/thailand-flag-xs.png',
    au: './assets/images/flags/australia-flag-xs.png',
    tr: './assets/images/flags/turkey-flag-xs.png',
    es: './assets/images/flags/mexico-flag-xs.png',
  };

  public currentLanguageObj: LanguageOption;
  public _currentLanguage = this.getStoredLanguage();
  public _onLanguageChange = new Subject<string>();
  public _currentCurrency = this.getStoredCurrency();
  public currentCurrencyObj: LanguageOption;

  constructor(
    public translate: TranslateService
  ) {}

  get languages(): LanguageOption[] {
        return JSON.parse(JSON.stringify(this._languages));
  }

  get currentLanguage() {
      return this._currentLanguage;
  }

  get onLanguageChange() {
      return this._onLanguageChange.asObservable();
  }

  set setCurrentLanguage(language: string) {
      if (!language) {
          language = localStorage.getItem('lang');
          if(language){
              this.translate.setDefaultLang(language);
              this._onLanguageChange.next(language);
              return;
          }else{
              this.translate.setDefaultLang('en');
              this._onLanguageChange.next(language);
              return;
          }
      }
      this.translate.setDefaultLang(language);

      if (!language || !this._languages.find(lang => lang.language === language)) {
          return;
      }

      this.currentLanguageObj = this._languages.find(lang => lang.language === language);
      localStorage.setItem('lang', language);
      this._currentLanguage = language;
      setTimeout(() => {
        if(typeof(zE) !== 'undefined') {
          zE('webWidget', 'setLocale', this._languages.find(item => item.language === language).locale);
        }
      });
      this._onLanguageChange.next(language);
  }

  set setCurrentCurrency(language: string) {
    if (!language) {
        language = localStorage.getItem('currency');
    }

    if (!language || !this._languages.find(lang => lang.currencyCode === language)) {
        return;
    }

    this.currentCurrencyObj = this._languages.find(lang => lang.currencyCode === language);
    localStorage.setItem('currency', language);
    this._currentLanguage = language;
    this._onLanguageChange.next(language);
  }

  getStoredLanguage() {
    this.translate.addLangs(['zh', 'en', 'zh_TW', 'kr', 'vn', 'jp', 'ru']);
    const lang = localStorage.getItem('lang');
    this.translate.setDefaultLang(lang || 'en');

    if (lang && this._languages.find(language => language.language === lang)) {
      this.currentLanguageObj = this._languages.find(language => language.language === lang);
      setTimeout(() => {

        if(typeof(zE) !== 'undefined') {
          zE('webWidget', 'setLocale', this._languages.find(item => item.language === lang).locale);
        }
      });
      return lang;
    }
    this.currentLanguageObj = this._languages.find(language => language.language === 'en');
    return 'en';
  }

  getStoredCurrency() {
    const lang = localStorage.getItem('currency');

    if (lang && this._languages.find(language => language.currencyCode === lang)) {
      this.currentCurrencyObj = this._languages.find(language => language.currencyCode === lang);
      return lang;
    }
    this.currentCurrencyObj = this._languages.find(language => language.currencyCode === 'USD');
    return 'en';
  }

  getFlagImage(country: string) {
    if (country) {
      return this.languageFlagMap[country.toLowerCase()];
    }
  }

  getSymbol() {
    return this.currentCurrencyObj.symbol;
  }

  setCurrency(selected) {
    return this.setCurrentCurrency = selected;
  }
}
