import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { ForTranslateService } from '../../services/for-translate.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-portfolio-widget',
  templateUrl: './portfolio-widget.component.html',
  styleUrls: ['./portfolio-widget.component.scss'],
})
export class PortfolioWidgetComponent implements OnInit {

  chartData: ChartDataSets[] = [
    {
      data: [36500, 36000, 35500, 36500, 35000, 36000, 35000],
      label: '',
    },
  ];
  chartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];
  chartType: ChartType = 'line';

  constructor(
    public translateService: ForTranslateService,
    public walletService: WalletService
  ) { }

  ngOnInit() {}
}
