import { Component, Input, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-countdown-timer',
  template: `{{countdown | date:'H:mm:ss':'UTC'}}s`
})
export class CountdownTimerComponent implements OnInit, OnDestroy {

  @Input() futureUnixTimeStamp: number;
  @Input() amountOfMilliseconds: number;

  countdown: number;
  settimeout: any;

  constructor() { }

  ngOnInit(): void {

    if(this.amountOfMilliseconds) {
      this.countdown = Number(this.amountOfMilliseconds);
      this.countdownRecursive();
    } else if (!this.amountOfMilliseconds && this.futureUnixTimeStamp) {
      this.countdown = Number(this.futureUnixTimeStamp)/1000 - new Date().getTime()/1000;
      this.countdownUnixRecursive();
    }
  }

  countdownRecursive() {
    if(this.countdown < 1) {
      clearTimeout(this.settimeout);
      this.countdown = 0;
      return;
    }

    this.settimeout = setTimeout(() => {
      this.countdown -= 1000;
      this.countdownRecursive();
    }, 1000);
  }

  countdownUnixRecursive() {
    if(this.countdown < 1) {
      clearTimeout(this.settimeout);
      this.countdown = 0;
    }

    this.settimeout = setTimeout(() => {
      this.countdown -= 1000;
      this.countdownUnixRecursive();
    }, 1000);
  }

  ngOnDestroy() {
    clearTimeout(this.settimeout);
  }

}
