import { Component, OnInit } from '@angular/core';
import { ForTranslateService } from 'src/app/services/for-translate.service';

@Component({
  selector: 'app-currency-select',
  templateUrl: './currency-select.component.html',
  styleUrls: ['./currency-select.component.scss'],
})
export class CurrencySelectComponent implements OnInit {

  selected = this.translateService.currentCurrencyObj.currencyCode;

  constructor(
    public translateService: ForTranslateService
  ) { }

  ngOnInit() {}

}
