import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  pageNumber = 1;
  show = true;
  constructor() { }

  ngOnInit() {
  }

  previous() {
    this.pageNumber--;
    this.checkPage();
  }
  next() {
    this.pageNumber++;
    this.checkPage();
  }

  checkPage() {
    if (this.pageNumber > 1) {
      this.show = false;
    } else {
      this.show = true;
    }
  }

}
