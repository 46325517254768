import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { LoginObj } from '../../models/auth/loginObj';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Input() height = 85;
  @Output() trigger = new EventEmitter();
  @Output() toggle = new EventEmitter();
  @Output() shakeLogin = new EventEmitter();
  //THE FORM CONTROL
  get primEmail() {
    return this.userEmails.get('primaryEmail');
  }
  title = 'email-validation';
  userEmails = new FormGroup({
    primaryEmail: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    password: new FormControl('', [Validators.required]),
  });
  // VARIABLES FOR THE LOGIN
  textEmailLabel = 'email-or-mighty-account';
  emailValid: boolean;
  mightyNumber = false;
  inputType = 'password';
  warning;
  warningTwo;
  loginCode;
  loginObj: LoginObj = {
    account: '',
    password: '',
  };
  //Related to Animations
  isLoading = false;
  textButtonAnimation = 'sign-in';
  status: 'Done';
  clicked = false;
  dataReceived = false;
  activateAnimation = true;
  submitDisabled = true;

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  validateEmail(event: any) {
    // this.email = event;
    this.loginObj.account = event;
    const input = document.querySelector('input');
    const square = document.getElementsByClassName('square');
    const label = document.querySelector('label');
    // this is checking to see if they have mighty account number
    if (event.match('[0-9]{7}$')) {
      this.mightyNumber = true;
    } else {
      this.mightyNumber = false;
    }
    if (this.primEmail.status === 'VALID' || this.mightyNumber) {
      // THIS BLOCK SHOWS THE GREEN MESSAGE
      input.classList.remove('invalid');
      input.classList.add('valid');
      label.classList.remove('invalid');
      label.classList.add('valid');
      square[0].classList.remove('invalid');
      square[0].classList.add('valid');
      this.emailValid = true;
    } else if (
      (this.primEmail.status !== 'VALID' || this.mightyNumber === false) &&
      this.activateAnimation === true
    ) {
      // THIS BLOCK REMOVES GREEN OR RED MESSAGE
      this.emailValid = null;
      input.classList.remove('invalid');
      input.classList.remove('valid');
      label.classList.remove('invalid');
      label.classList.remove('valid');
      square[0].classList.remove('valid');
      square[0].classList.remove('invalid');
    }
    this.checkForm();
  }

  // THIS WILL CHECK ON BLUR TO SEE IF EMAIL IS CORRECT OR NOT
  checkEmail() {
    if (
      this.primEmail.status !== 'VALID' &&
      this.loginObj.account !== '' &&
      this.mightyNumber === false
    ) {
      const input = document.querySelector('input');
      const square = document.getElementsByClassName('square');
      const label = document.querySelector('label');
      // THIS BLOCK SHOWS THE RED MESSAGE
      input.classList.remove('valid');
      input.classList.add('invalid');
      label.classList.remove('valid');
      label.classList.add('invalid');
      square[0].classList.add('invalid');
      square[0].classList.remove('valid');
      this.emailValid = false;
    }
  }

  // THIS WILL DELETE THE WHOLE EMAIL FIELD WHEN RED MESSAG APPEARS
  deleteInput() {
    //Activate Animation is critical for toggling the green and red animation
    this.activateAnimation = false;
    if (this.emailValid === false) {
      const input = document.querySelector('input');
      const square = document.getElementsByClassName('square');
      const label = document.querySelector('label');
      square[0].classList.add('slide');
      setTimeout(() => {
        this.loginObj.account = '';
        input.classList.remove('valid');
        label.classList.remove('valid');
        square[0].classList.remove('valid');
      }, 100);
      setTimeout(() => {
        square[0].classList.remove('slide');
        square[0].classList.remove('invalid');
        label.classList.remove('invalid');
        input.classList.remove('invalid');
        this.activateAnimation = true;
      }, 500);
    }
  }

  validatePassword(event) {
    this.loginObj.password = event;
    this.checkForm();
  }

  //THE EYE BUTTON TO TOGGLE
  showPassword() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }

  // THIS IS WHERE API CALLS WILL HAPPEN SPECIFICALLY FOR THE BUTTON ANIMATION
  // DEPENDING ON WHAT YOU GET BACK FROM THE API, apply conditional accordingly
  onSubmit() {
    if (this.submitDisabled === false) {
      this.clicked = true;
      this.isLoading = true;
      //Frank Start here
      setTimeout(() => {
        this.authService.login(this.loginObj).subscribe((data: any) => {
          this.loginCode = data.code;
          console.log('logincode', this.loginCode);
          if (this.loginCode !== '0' || this.loginCode === '0') {
            this.dataReceived = true;
            setTimeout(() => {
              this.trigger.emit();
            }, 1000);
          } else {
            this.textButtonAnimation = 'invalid-login-try-again';
            this.isLoading = false;
            this.shakeLogin.emit();
            setTimeout(() => {
              this.textButtonAnimation = 'sign-in';
            }, 2500);
          }
        });
        this.clicked = false;
      }, 1000);
    } else {
      if (this.emailValid === null) {
        this.warning = true;
        setTimeout(() => {
          this.warning = false;
        }, 400);
      }
      if (this.loginObj.password === '' || null || undefined) {
        this.warningTwo = true;
        setTimeout(() => {
          this.warningTwo = false;
        }, 400);
      }
    }
  }

  checkForm() {
    if (
      (this.emailValid && this.loginObj.password !== '') ||
      null ||
      undefined
    ) {
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
    }
  }
}
