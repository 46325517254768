import { animate, animateChild, group, query, sequence, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('expandingCircle', [
      transition('auth => home', [
        style({
          'background-color': 'var(--ion-color-primary)',
          'background-image': 'url(../assets/logo/mighty-background.png)',
          'background-repeat': 'no-repeat',
          'background-size': '90% auto',
          'background-position': 'bottom 25% center'
        }),
        group([
          query('@authPage', [
            animateChild({duration: 500})
          ]),
          query('@expandingCircle2', [
            animateChild({duration: 500})
          ]),
        ]),
        query(':enter', [
          animateChild({duration: 200})
        ])
      ])
    ])
  ]
})
export class AppComponent {
  title = 'Mightyio';
  constructor(
    public router: Router
  ) {

  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
