import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate   {
  constructor(
    private router: Router
    ) {
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    // if (!false) {
    //   this.router.navigate(['/auth']);
    //   return false;
    // }

    // if (!false) {

    // }
    // You can save redirect url so after authing we can move them back to the page they requested
    return true;
  }
}
