import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'checkZero'
})
export class CheckZeroPipe implements PipeTransform {

  constructor(
    private decimalPipe: DecimalPipe
  ) {

  }

  transform(value: string | number, ...args: unknown[]): unknown {
    if (value) {
      return value;
    } else {
      return this.decimalPipe.transform(value, '1.2-2');
    }
  }

}
