import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { Contacts } from 'src/app/models/common/contacts';
import { AccountService } from '../../services/account.service';
import { WalletService } from '../../services/wallet.service';

@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.scss'],
})
export class ContactsListComponent implements OnInit, OnDestroy {
  @Output() selectedContact = new EventEmitter<Contacts>();
  contacts: Contacts[];
  constructor(
    public accountService: AccountService,
    public walletService: WalletService
  ) { }

  ngOnInit() {
    this.accountService.getContacts().subscribe((data)=>{
      this.contacts = data.contacts;
    });
  }

  selected(contact: Contacts) {
    this.walletService.selected = contact;
    this.selectedContact.emit();
  }

  ngOnDestroy() {
  }
}
