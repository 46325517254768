import { Component, Input, OnInit } from '@angular/core';
import { AppStoreService } from '../../services/app-store.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-go-back-button',
  templateUrl: './go-back-button.component.html',
})
export class GoBackButtonComponent implements OnInit {

  @Input() left: string = null;
  @Input() right: string = null;
  @Input() position: string;
  @Input() mobile = false;

  constructor(
    private appStore: AppStoreService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClick() {
    if (this.appStore.previousRoute) {
      this.location.back();
    } else {
      this.router.navigate(['/home/dashboard/']);
    }
  }

}
