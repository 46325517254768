/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, of } from 'rxjs';
import { Contacts } from '../models/common/contacts';
import { Wallet } from '../models/wallet/wallet';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  selected: Contacts;
  selectedCoin: any = {};

  primaryAsset = 'BTC';
  placeholderAsset = 'DOGE';

  assets = [
    {
      coinName: 'Bitcoin',
      coinCode: 'BTC',
      total: 2.04355,
      convertRate: 33333,
      percentChange: 14.6,
      lockedAmount: 1.023456,
      portfolioPercentage: 65.24,
    },
    {
      coinName: 'DogeCoin',
      coinCode: 'DOGE',
      total: 350.482,
      convertRate: .20,
      percentChange: -4.6,
      lockedAmount: 2890809.492939,
      portfolioPercentage: 21.31,
    },
    {
      coinName: 'Ethereum',
      coinCode: 'ETH',
      total: 1.293048395,
      convertRate: 2100,
      percentChange: 3.6,
      lockedAmount: .6534967,
      portfolioPercentage: 13.45,
    },
    {
      coinName: 'USDCoin',
      coinCode: 'USDC',
      total: 0,
      convertRate: 1,
      percentChange: 0,
      lockedAmount: 0,
      portfolioPercentage: 0,
    }
  ];

  balance = {
    estimatedBalance: 25000,
    activity: 15546.35,
    percentChange: -4.5
  };

  currentAssets = {
    currentAssets: 73123.32,
    percentChange: 4.2,
    assetsBreakdown: {
      BTC: {
        amount: 2.04,
        coinCode: 'BTC',
        coinName: 'Bitcoin',
        coinConvert: 33000
      },
      DOGE: {
        amount: 2.04,
        coinCode: 'DOGE',
        coinName: 'Dogecoin',
        coinConvert: .24
      },
      ETH: {
        amount: 2.04,
        coinCode: 'ETH',
        coinName: 'Ethereum',
        coinConvert: 4.2
      }
    }
  };

  summary = {
    activityAmount: 25000,
    percentChange: -4.5,
  };

  availableToTradeAssets = [
    {
      symbol: 'BTC',
      name: 'Bitcoin',
      id: 'bitcoin',
      amount: 2.34,
      conversion: {
        usd: 31000,
        cny: 200209,
        twd: 866502
      },
      image: 'assets/images/coins/BTC-min.png'
    },
    {
      symbol: 'AAVE',
      name: 'AAVE',
      id: 'aave',
      amount: 4.34,
      conversion: {
        usd: 237,
        cny: 1538,
        twd: 6657
      },
      image: 'assets/images/coins/AAVE-min.png'
    },
    {
      symbol: 'ETH',
      name: 'Ethereum',
      id: 'ethereum',
      amount: 8.34,
      conversion: {
        usd: 2100,
        cny: 5869,
        twd: 58991
      },
      image: 'assets/images/coins/ETH-min.png'
    },
    {
      symbol: 'LTC',
      name: 'Litecoin',
      id: 'litecoin',
      amount: 9.34,
      conversion: {
        usd: 113,
        cny: 315,
        twd: 3150
      },
      image: 'assets/images/coins/LTC-min.png'
    },
    {
      symbol: 'LINK',
      name: 'Chainlink',
      id: 'chainlink',
      amount: 16.34,
      conversion: {
        usd: 14.6,
        cny: 40,
        twd: 400
      },
      image: 'assets/images/coins/LINK-min.png'
    },
    {
      symbol: 'DOGE',
      name: 'Dogecoin',
      id: 'doge',
      amount: 12.34,
      conversion: {
        usd: .14,
        cny: 4,
        twd: 40
      },
      image: 'assets/images/coins/DOGE-min.png'
    },
    {
      symbol: 'ADA',
      name: 'Cardano',
      id: 'ada',
      amount: 8.34,
      conversion: {
        usd: 1.13,
        cny: 31,
        twd: 310
      },
      image: 'assets/images/coins/ADA-min.png'
    },
    {
      symbol: 'SUSHI',
      name: 'Sushi Swap',
      id: 'sushi-swap',
      amount: 6.41,
      conversion: {
        usd: 6.66,
        cny: 18.6,
        twd: 186
      },
      image: 'assets/images/coins/SUSHI-min.png'
    },
  ];

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  getWalletsInfo(): Observable<any> {
    return of([new Wallet({
      name: 'main wallet',
      priority: 'default',
      assets: this.assets
    })]);
  }

  createNewWallet(name: string): Observable<any> {
    return of(new Wallet({
      name,
      priority: 'secondary',
      assets: []
    }));
  }

  // getUserBalance() {
  //   const url = `${environment.walletUrl}/user/balance/queryUserBalanceMessage`;
  //   const headers = new HttpHeaders()
  //     .set('Content-Type', this.authService.authObj.contentType);
  //   const body = new HttpParams()
  //     .set('pageNumber', 1)
  //     .set('pageSize', 20)
  //     .set('coin', 'BTC');
  //   return this.http.get(url, { headers });
  // }
}
