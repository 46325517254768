import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AppStoreService } from '../services/app-store.service';

@Directive({
  selector: '[appShakeScreen]'
})
export class ShakeScreenDirective {
  @Input() appShakeScreen: boolean;
  constructor(
    private el: ElementRef,
    private appStore: AppStoreService
  ) {

  }

  @HostListener('click')
    onClick() {
      if (this.appShakeScreen) {
        this.el.nativeElement.classList.add('shake-button');
        if (this.appStore.failureCount >= this.appStore.failureCountMin) {
          document.getElementById('main-container').classList.add('shake-button');
        }

        setTimeout(()=>{
          this.el.nativeElement.classList.remove('shake-button');
          if (this.appStore.failureCount >= this.appStore.failureCountMin) {
            document.getElementById('main-container').classList.remove('shake-button');
          }
        }, 500);
      }
    }
}
