import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dotted-line-button',
  templateUrl: './dotted-line-button.component.html',
  styleUrls: ['./dotted-line-button.component.scss']
})
export class DottedLineButtonComponent implements OnInit {

  @Input() text: string;
  @Input() width: string;
  @Input() marginLeft: string;
  @Output() emitClick = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onClickHandler() {
    this.emitClick.emit();
  }

}
