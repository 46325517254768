import { Component, Input, OnInit } from '@angular/core';
import { QuickLink } from 'src/app/models/common/quick-links';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss']
})
export class QuickLinksComponent implements OnInit {

  @Input() links: QuickLink[];

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  navigate(option: string) {
    switch(option) {
      case 'send-invoice':
        this.router.navigate(['/home/dashboard/create-invoice']);
        break;
    }
  }

}
