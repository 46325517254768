import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Contacts } from '../models/common/contacts';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor() { }

  getContacts(): Observable<any> {
    return of({
      contacts: [
        {
          name: 'John Jones',
          profile: '../../assets/images/sample/dino.png',
          id: 8,
          email: 'something@something.com'
        },
        {
          name: 'Satoshi Nakamoto',
          profile: '../../assets/images/sample/steg.png',
          id: 8,
          email: 'something@something.com'
        },
        {
          name: 'Alexander Petrovsky',
          profile: '../../assets/images/sample/ptera.png',
          id: 8,
          email: 'something@something.com'
        }
      ] as Contacts[]
    });
  }
}
