import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { AuthObj } from '../models/auth/auth-obj';
import { LoginObj } from '../models/auth/loginObj';
import md5 from 'crypto-js/md5';
import { of } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authObj: AuthObj = {
    username: 'Bob Bob',
    from: 'WEB',
    contentType: 'application/x-www-form-urlencoded',
  };

  isLoggedIn = false;

  constructor(private http: HttpClient) {}

  login(loginObj: LoginObj) {
    const password = md5(loginObj.password).toString().toUpperCase();
    const url = `${environment.imUrl}web/webLogin`;
    const headers = new HttpHeaders()
      .set('from', this.authObj.from)
      .set('Content-Type', this.authObj.contentType);
    const body = new HttpParams()
      .set('accountNumber', loginObj.account)
      .set('password', password)
      .set('webPublicKey', 'MIGf');
    // return this.http.post(url, body, { headers });
    return of({code: 1});
  }

  register() {
    const password = md5().toString().toUpperCase();
    const url = `${environment.imUrl}/user/registerUser`;
    const headers = new HttpHeaders()
      .set('from', this.authObj.from)
      .set('Content-Type', this.authObj.contentType);
    const body = new HttpParams()
      .set('verifyId', '')
      .set('registrationType', 'MAIL')
      .set('accountNumber', '')
      .set('password', '');

  }
}
// 1057229;
// 950C454EDA45C9DB39E2811557E0F9DD
