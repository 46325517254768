import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberPipe'
})
export class NumberPipePipe implements PipeTransform {

  transform(val) {
    val = this.formatNumber(val, '');
    return val;
  }

  formatNumber(numberValue, prefix) {
    const thousandSeparator = ',';
    const decimalSeparator = '.';
    const regex = new RegExp('[^' + decimalSeparator + '\\d]', 'g');
    const numberString = numberValue ? numberValue.replace(regex, '').toString() : '';
    const split = numberString.split(decimalSeparator);
    const rest = split[0].length % 3;
    let result = split[0].substr(0, rest);
    const thousands = split[0].substr(rest).match(/\d{3}/g);

    if (thousands) {
      const separator = rest ? thousandSeparator : '';
      result += separator + thousands.join(thousandSeparator);
    }
    result = split[1] !== undefined ? result + decimalSeparator + split[1] : result;
    return prefix === undefined ? result : (result ? prefix + result : '');
  };
}
