import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'assetAmount'
})
export class AssetAmountPipe implements PipeTransform {

  transform(value: number): number {
    if(!value) {
      return null;
    }
    if(value > 0) {
      return Number(value.toFixed(4));
    }
    if(value > 0.001) {
      return Number(value.toFixed(6));
    }
    return Number(value.toFixed(10));
  }

}
