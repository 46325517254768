import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RoutingModule } from './routing/routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCommonModule } from './common/common.module';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { RouteReuseStrategy } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { DemoButtonsComponent } from './demo-buttons/demo-buttons.component';
import { PreAccessComponent } from './pre-access/pre-access.component';


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function createTranslateLoader(http: HttpClient) {
  // eslint-disable-next-line max-len
  return new TranslateHttpLoader(http, 'https://0uly321v14.execute-api.ap-southeast-1.amazonaws.com/default/gsn-translation/list?language=','');
}

@NgModule({
  declarations: [
    AppComponent,
    DemoButtonsComponent,
    PreAccessComponent,
  ],
  imports: [
    BrowserModule,
    AppCommonModule,
    RoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    IonicModule.forRoot({
      mode: 'md'
    })
  ],
  providers: [
    DecimalPipe,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
