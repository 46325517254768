import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInAnimation } from '../../animations/fadein';

@Component({
  selector: 'app-pop-up-fullscreen',
  templateUrl: './pop-up-fullscreen.component.html',
  styleUrls: ['./pop-up-fullscreen.component.scss'],
  animations: [fadeInAnimation]
})
export class PopUpFullscreenComponent implements OnInit {

  @Input() text: string;
  @Input() open: boolean;
  @Output() closeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  onCloseHandler() {
    this.closeEvent.emit();
  }

}
