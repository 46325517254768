import { Component, OnInit } from '@angular/core';
import { ForTranslateService } from '../../services/for-translate.service';

@Component({
  selector: 'app-convert-currency',
  templateUrl: './convert-currency.component.html',
  styleUrls: ['./convert-currency.component.scss'],
})
export class ConvertCurrencyComponent implements OnInit {

  constructor(
    public translateService: ForTranslateService
  ) { }

  ngOnInit() {}

}
