import { trigger, query, transition, stagger, animate, style, animateChild } from '@angular/animations';

export const fadeInAnimation = trigger('fadein', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0}),
      animate('{{time}}', style({ opacity: 1}))
    ],{ optional: true })
  ],{params: {time: '0.5s'}})
]);
