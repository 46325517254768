import { TransactionActivity } from '../common/activity';
export class Transaction extends TransactionActivity{
  constructor(transaction: any) {
    super(transaction);
  }

  getType() {
    return this.type ? 'payment-to' : 'received-from';
  }
}
