import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-demo-buttons',
  templateUrl: './demo-buttons.component.html',
  styleUrls: ['./demo-buttons.component.scss']
})
export class DemoButtonsComponent implements OnInit {

  methods = [
    {
      name: 'Bitcoin',
      symbol: 'BTC'
    },
    {
      name: 'Tether',
      symbol: 'USDT'
    },
    {
      name: 'Ethereum',
      symbol: 'ETH'
    }
  ];

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  onClick(symbol: any) {
    this.router.navigate(['/checkout'], {queryParams: {symbol}});
  }

}
