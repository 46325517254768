import { Component, OnInit } from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { trigger, state, style, transition, animate, query, keyframes } from '@angular/animations';
import { AppStoreService } from '../../services/app-store.service';

@Component({
  selector: 'app-trade-crypto-widget',
  templateUrl: './trade-crypto-widget.component.html',
  styleUrls: ['./trade-crypto-widget.component.scss'],
  animations: [
    trigger('spin', [
      state('active', style({
        transform: 'rotateZ(0)'
      })),
      state('inactive', style({
        transform: 'rotateZ(180deg)'
      })),
      transition('active <=> inactive', [
        animate('0.2s ease-in-out')
      ])
    ]),
    trigger('spin2', [
      state('active', style({
        transform: 'rotateY(0)'
      })),
      state('inactive', style({
        transform: 'rotateY(-360deg)'
      })),
      transition('active <=> inactive', [
        animate('0.75s ease-out')
      ])
    ]),
    trigger('popup', [
      transition(':enter', [
        query('.popup-coin', [
          animate('1s', keyframes([
            style({transform: 'rotateY(0)', offset:0}),
            style({transform: 'rotateY(75deg)', offset:0.3}),
            style({transform: 'rotateY(285deg)', offset:0.7}),
            style({transform: 'rotateY(360deg)', offset:1}),
          ]))
        ])
      ])
    ])
  ]
})
export class TradeCryptoWidgetComponent implements OnInit {

  inputAsset: any;
  receiveAsset: any;
  inputs = {
    from: null,
    to: null
  };
  popup: PopUpMenu = {
    active: false,
    selector: ''
  };
  buttonText = 'trade-instantly';
  spinAnimation = true;

  constructor(
    public walletService: WalletService,
    public appStore: AppStoreService
  ) { }

  ngOnInit() {
    this.initialSetup();
  }

  initialSetup() {
    if(this.appStore.activeWallet === 'default') {
      this.inputAsset = this.appStore.wallets[0].getAssets()[0];
      this.receiveAsset = this.appStore.wallets[0].getAssets()[1];
    } else {
      this.inputAsset = this.appStore.wallets[1].getAssets()[0];
      this.receiveAsset = this.appStore.wallets[1].getAssets()[1];
    }
  }

  getImage(coin) {
    return `../../../assets/images/coins/${coin}-min.png`;
  }

  switchAssets() {
    this.spinAnimation = !this.spinAnimation;
    const placeholder = this.inputAsset;
    this.inputAsset = this.receiveAsset;
    this.receiveAsset = placeholder;
    this.onInputChangeHandler('input', this.inputs.from);
  }

  openPopUpHandler(selector: string) {
    this.popup.selector = selector;
    this.popup.active = true;
  }

  onPopUpSelect(coin: any) {
    if(this.popup.selector === 'input') {
      this.inputAsset = {
        coinCode: coin.symbol,
        coinName: coin.name,
        total: (Math.random() * 100)
      };
    } else {
      this.receiveAsset = {
        coinCode: coin.symbol,
        coinName: coin.name,
        total: (Math.random() * 100)
      };
    }
    this.popup.active = false;
    this.onInputChangeHandler('input', this.inputs.from);
  }

  onInputChangeHandler(selector: string, amount: number) {
    const random = Math.random()*100;

    switch(selector) {
      case 'input':
        this.inputs.from = amount;
        if(amount) {
          this.inputs.to = (this.inputs.from * random).toFixed(2);
        } else {
          this.inputs.to = null;
        }
        break;

      case 'output':
        this.inputs.to = amount;
        if(amount) {
          this.inputs.from = (this.inputs.to * random).toFixed(2);
        } else {
          this.inputs.from = null;
        }
        break;
    }

    if(this.inputs.from > this.inputAsset.total) {
      this.buttonText = 'not-enough-asset';
    }
     else {
       this.buttonText = 'trade-instantly';
     }
  }

}

export interface PopUpMenu {
  active: boolean;
  selector: string;
}
