import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/authguard.guard';
import { DemoButtonsComponent } from '../demo-buttons/demo-buttons.component';
import { PreAccessComponent } from '../pre-access/pre-access.component';
import { PreAccessGuard } from '../pre-access/pre-access.guard';


const routes: Routes = [
  {
    path: 'beta-access',
    component: PreAccessComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule),
    canActivate: [PreAccessGuard],
    data: {
      animation: 'auth'
    },
  },
  {
    path: 'checkout',
    loadChildren: () => import('../checkout/checkout.module').then(m => m.CheckoutModule),
    canActivate: [PreAccessGuard],
    data: {
      animation: 'home'
    },
  },
  {
    path: 'home',
    loadChildren: () => import('../home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard, PreAccessGuard],
    data: {
      animation: 'home'
    }
  },
  {
    path: 'external',
    component: DemoButtonsComponent,
    canActivate: [PreAccessGuard]
  },
  {
    path: '',
    redirectTo: '/auth',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class RoutingModule { }
