import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
})
export class MobileNavMenuComponent implements OnInit {
  mobileMenu = [
    {
      name: 'my-dashboard',
      routerLink: '/home/dashboard/',
    },
    {
      name: 'wallet',
      routerLink: '/home/wallet/',
    },
    {
      name: 'trade-crypto',
      routerLink: '/home/trade',
    },
    {
      name: 'activity',
      routerLink: '/home/activity',
    },
    {
      name: 'affiliate',
      routerLink: '/home/affiliate'
    },
  ];

  constructor() {}

  ngOnInit() {}
}
