import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent implements OnInit {

  @Input() filters: string[];
  @Input() title: string;
  @Input() activityRoute: string;
  selected: string;
  startDate = new FormControl(new Date());
  endDate = new FormControl(new Date());

  constructor() { }

  ngOnInit() {}

  filterList(filter) {
    this.selected = filter;
  }

}
