import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { TranslatePipe } from '../pipes/translate.pipe';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { AnimatedBellComponent } from './animated-bell/animated-bell.component';
import { CreditWidgetComponent } from './credit-widget/credit-widget.component';
import { PortfolioWidgetComponent } from './portfolio-widget/portfolio-widget.component';
import { ChartComponent } from './chart/chart.component';
import { ChartsModule } from 'ng2-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatOptionModule } from '@angular/material/core';
import { ConvertCurrencyComponent } from './convert-currency/convert-currency.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PercentChangeComponent } from './percent-change/percent-change.component';
import { CheckZeroPipe } from '../pipes/check-zero.pipe';
import { TradeCryptoWidgetComponent } from './trade-crypto/trade-crypto-widget.component';
import { MatIconModule } from '@angular/material/icon';
import { TableHeaderComponent } from './table-header/table-header.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ShakeScreenDirective } from '../directives/shake-screen.directive';
import { ContactsListComponent } from './contacts-list/contacts-list.component';
import { AssetAmountPipe } from '../pipes/asset-amount.pipe';
import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { NumberPipePipe } from '../pipes/number-pipe.pipe';
import { CurrencySelectComponent } from './currency-select/currency-select.component';
import { PopUpFullscreenComponent } from './pop-up-fullscreen/pop-up-fullscreen.component';
import { DropDownComponent } from './drop-down/drop-down.component';
import { ActivityTableComponent } from './activity-table/activity-table.component';
import { AnimatedConfettiComponent } from './animated-confetti/animated-confetti.component';
import { CoinDropDownComponent } from './coin-drop-down/coin-drop-down.component';
import { DottedLineButtonComponent } from './dotted-line-button/dotted-line-button.component';
import { InputCurrencySwapComponent } from './input-currency-swap/input-currency-swap.component';
import { FooterComponent } from '../common/footer/footer.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { GoBackButtonComponent } from './go-back-button/go-back-button.component';
import { RegistrationFormComponent } from './registration-form/registration-form.component';
import { PushingPixelsComponent } from './pushing-pixels/pushing-pixels.component';
import { AuthLogRegBoxComponent } from './auth-log-reg-box/auth-log-reg-box.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MobileNavMenuComponent } from './mobile-nav-menu/mobile-nav-menu.component';


@NgModule({
  declarations: [
    HeaderComponent,
    TranslatePipe,
    AssetAmountPipe,
    LanguageSelectComponent,
    AnimatedBellComponent,
    CreditWidgetComponent,
    PortfolioWidgetComponent,
    ChartComponent,
    ConvertCurrencyComponent,
    SearchBarComponent,
    PercentChangeComponent,
    CheckZeroPipe,
    TradeCryptoWidgetComponent,
    TableHeaderComponent,
    QuickLinksComponent,
    LoginFormComponent,
    ShakeScreenDirective,
    ContactsListComponent,
    TransactionDetailsComponent,
    NumberPipePipe,
    CurrencySelectComponent,
    PopUpFullscreenComponent,
    DropDownComponent,
    ActivityTableComponent,
    AnimatedConfettiComponent,
    CoinDropDownComponent,
    DottedLineButtonComponent,
    InputCurrencySwapComponent,
    FooterComponent,
    CountdownTimerComponent,
    GoBackButtonComponent,
    RegistrationFormComponent,
    PushingPixelsComponent,
    AuthLogRegBoxComponent,
    PaginationComponent,
    MobileNavMenuComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    ChartsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSelectModule,
    MatOptionModule,
    MatCheckboxModule,
    MatIconModule,
  ],
  exports: [
    CommonModule,
    HeaderComponent,
    TranslatePipe,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    LanguageSelectComponent,
    AnimatedBellComponent,
    CreditWidgetComponent,
    PortfolioWidgetComponent,
    ChartComponent,
    ChartsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatSelectModule,
    ConvertCurrencyComponent,
    SearchBarComponent,
    MatCheckboxModule,
    PercentChangeComponent,
    CheckZeroPipe,
    AssetAmountPipe,
    TradeCryptoWidgetComponent,
    MatIconModule,
    TableHeaderComponent,
    QuickLinksComponent,
    LoginFormComponent,
    TransactionDetailsComponent,
    ShakeScreenDirective,
    ContactsListComponent,
    NumberPipePipe,
    CurrencySelectComponent,
    DropDownComponent,
    ActivityTableComponent,
    AnimatedConfettiComponent,
    CoinDropDownComponent,
    DottedLineButtonComponent,
    InputCurrencySwapComponent,
    FooterComponent,
    CountdownTimerComponent,
    GoBackButtonComponent,
    RegistrationFormComponent,
    PushingPixelsComponent,
    AuthLogRegBoxComponent,
    PaginationComponent,
    MobileNavMenuComponent
  ],
})
export class AppCommonModule {}
