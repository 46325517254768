import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Transaction } from '../../models/transactions/transaction';
import { TransactionsService } from '../../services/transactions.service';

@Component({
  selector: 'app-transaction-details',
  templateUrl: './transaction-details.component.html',
  styleUrls: ['./transaction-details.component.scss']
})
export class TransactionDetailsComponent implements OnInit {

  //create a model for this
  transaction: Transaction;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private transactionService: TransactionsService
  ) { }

  ngOnInit(): void {
    if(!this.route.snapshot.paramMap.get('id')) {
      this.router.navigate(['/home/activity']);
    }
    this.getTransaction();
  }

  getTransaction() {
    this.transactionService.getTransactionDetail(Number(this.route.snapshot.paramMap.get('id'))).subscribe((res: any) => {
      this.transaction = res;
    });
  }

  getBigRandom() {
    return Math.floor(Math.random()*1000);
  }

  getDate() {
    return new Date();
  }

}
