import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TransactionActivity } from '../../models/common/activity';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss']
})
export class ActivityTableComponent implements OnInit {

  @Input() filters = [
    'all-activity',
    'received',
    'sent',
    'exchange',
    'deposits',
    'withdrawals'
  ];
  @Input() list: any = [];
  @Input() title: string;
  @Output() emit: EventEmitter<TransactionActivity> = new EventEmitter();
  activityRoute;

  constructor(
    public activatedRoute: ActivatedRoute,
    private route: Router
  ) { }

  ngOnInit(): void {
    if (this.route.routerState.snapshot.url === '/home/activity') {
      this.activityRoute = true;
    } else {
      this.activityRoute = false;
    }
  }

  onClick(item: TransactionActivity) {
    this.emit.emit(item);
  }

}
