import { Component, OnInit } from '@angular/core';
import { ForTranslateService } from '../../services/for-translate.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service';

@Component({
  selector: 'app-credit-widget',
  templateUrl: './credit-widget.component.html',
  styleUrls: ['./credit-widget.component.scss'],
})
export class CreditWidgetComponent implements OnInit {

  constructor(
    public translateService: ForTranslateService,
    public walletService: WalletService,
    private router: Router,
    public accountService: AccountService
  ) { }

  ngOnInit() {
  }

  navigate(option: string) {
    switch(option) {
      case 'send':
        this.router.navigate(['/home/wallet/transact'], {queryParams: {method: 'send'}});
        break;

      case 'receive':
        this.router.navigate(['/home/wallet/transact'], {queryParams: {method: 'receive'}});
        break;
    }
  }

}
