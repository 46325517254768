import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fadeInAnimation } from 'src/app/animations/fadein';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit {

  @Input() image: boolean;
  @Input() imageKey: string;
  @Input() textKey: string;
  @Input() selected: any;
  @Input() placeholder: string;
  @Output() toggleEvent = new EventEmitter<void>();

  dropdown = false;

  constructor() { }

  ngOnInit(): void {
  }

  onClickHandler() {
    this.toggleEvent.emit();
  }

}
