export class Wallet {
  private name: string;
  private priority: string;
  private assets: any; //need an interface/model for this

  constructor(wallet?: any) {
    this.name = wallet ? wallet.name : '';
    this.priority = wallet ? wallet.priority : '';
    this.assets = wallet ? wallet.assets : [];
  }

  getName() {
    return this.name;
  }
  setName(name: string) {
    if(this.priority === 'secondary') {
      this.name = name;
    }
    return;
  }
  getAssets() {
    return this.assets;
  }
}
