import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import type { ChartData, ChartDataSets, ChartOptions } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { ChartType } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit, AfterViewInit {
  @Input() public chartData: ChartDataSets[];
  @Input() public chartLabels: Label[];
  @Input() public chartType: ChartType;

  @ViewChild('canvas', { static: false}) canvas: ElementRef;

  gradient;

  chartConfig: {
    chartOptions: (ChartOptions & { annotation: any });
    chartColors?: Color[];
    chartLegend?: boolean;
    chartPlugins: [];
  };

  public lineChartConfig: {
    chartOptions: (ChartOptions & { annotation: any });
    chartColors: Color[];
    chartLegend: boolean;
    chartPlugins: [];
  } = {
    chartOptions: {
      responsive: true,
      annotation: 'any',
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0
        }
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          }
        }],
        yAxes:[{

        }]
      }
    },
    chartColors: [
      {
        borderColor: 'rgba(7,60,250, 1)',
      },
    ],
    chartLegend: true,
    chartPlugins: []
  };

  public donutChartConfig: {
    chartOptions: (ChartOptions & { annotation: any });
    chartLegend?: boolean;
    chartPlugins: [];
  } = {
    chartOptions: {
      responsive: true,
      annotation: 'any',
      maintainAspectRatio: false,
      legend: {
        position: 'right'
      },
    },
    chartPlugins: []
  };

  constructor() { }

  ngOnInit() {
    this.getConfig();
  }

  getConfig() {
    if (this.chartType === 'line') {
      this.chartConfig = this.lineChartConfig;
    } else {
      this.chartConfig = this.donutChartConfig;
    }
  }

  ngAfterViewInit() {
    if (this.chartType === 'line') {
      this.gradient = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 130);
      this.gradient.addColorStop(0, 'rgba(7,60,250, .5)');
      this.gradient.addColorStop(0.5, 'rgba(7, 60, 250, 0.25)');
      this.gradient.addColorStop(1, 'rgba(7,60,250, 0)');
      this.chartConfig.chartColors[0] = {
        ...this.chartConfig.chartColors[0],
        backgroundColor: this.gradient
      };
    }
  }

}
