import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { staggerListAnimation } from '../../animations/staggerList';

@Component({
  selector: 'app-coin-drop-down',
  templateUrl: './coin-drop-down.component.html',
  styleUrls: ['./coin-drop-down.component.scss'],
  animations: [
    staggerListAnimation
  ]
})
export class CoinDropDownComponent implements OnInit {

  @Input() image: boolean;
  @Input() imageKey: string;
  @Input() textKey: string;
  @Input() list: any;
  @Input() placeholder: string;
  @Output() emitItem = new EventEmitter<any>();

  dropdown = false;
  selected: any;

  constructor() { }

  ngOnInit(): void {
  }

  onClickHandler() {
  }
  onSelect(item: any) {
    this.selected = item;
    this.dropdown = false;
    this.emitItem.emit(item);
  }

}
