import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footerSections = [
    {
      title: 'wallet',
      subSection: [
        {
          title: 'my-wallet',
          routerLink: '/home/wallet/',
        },
        {
          title: 'deposit',
          routerLink: '/home/dashboard/',
        },
        {
          title: 'history',
          routerLink: '/home/activity/',
        }
      ],
    },
    {
      title: 'about',
      subSection: [
        {
          title: 'Mighty.io',
          routerLink: '/home/dashboard/',
        },
        {
          title: 'corporate',
          routerLink: '/home/dashboard/',
        },
        {
          title: 'compliance',
          routerLink: '/home/dashboard/',
        }
      ],
    },
    {
      title: 'help',
      subSection: [
        {
          title: 'faq',
          routerLink: '/home/dashboard/',
        },
        {
          title: 'my-account',
          routerLink: '/home/account/',
        },
        {
          title: 'support',
          routerLink: '/home/dashboard/',
        }
      ],
    },
    {
      title: 'connect',
      subSection: [
        {
          title: 'Support@Mighty.io',
          routerLink: '/home/dashboard/',
        },
        {
          title: '635-374-3829',
          routerLink: '/home/dashboard/',
        },
        {
          title: 'live-chat',
          routerLink: '/home/dashboard/',
        }
      ],
    }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
