import { trigger, query, transition, stagger, animate, style, animateChild } from '@angular/animations';

export const staggerListAnimation = trigger('staggerList', [
  transition('* => *', [
    query(':enter', [
      style({ opacity: 0, transform: '{{scale}} {{translateY}}' }),
      stagger( 50, [
        animate('{{time}}', style({ opacity: 1, transform: 'scale(1) translateY(0)'}))
      ])
    ],{ optional: true })
  ],{params: {scale: 'scale(0.6)', time: '0.5s', translateY: 'translateY(-.8rem)'}})
]);
