import { Injectable } from '@angular/core';
import { Transaction } from '../models/transactions/transaction';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  transactions: Transaction[] = [
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'ETH',
      name: 'John Little',
      id: 1,
      network: 'Ethereum Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Satoshi Nakamoto',
      id: 2,
      network: 'Bitcoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 0,
      amount: 0.0002,
      currency: 'DOGE',
      name: 'Mighty Technologies LLC',
      id: 3,
      network: 'Dogecoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 0,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Some random guy',
      id: 4,
      network: 'Bitcoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Anonymous',
      id: 5,
      network: 'Bitcoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Anonymous',
      id: 5,
      network: 'Bitcoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Anonymous',
      id: 5,
      network: 'Bitcoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Anonymous',
      id: 5,
      network: 'Bitcoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Anonymous',
      id: 5,
      network: 'Bitcoin Network',
    }),
    new Transaction({
      dateTime: 1624959001900,
      type: 1,
      amount: 0.0002,
      currency: 'BTC',
      name: 'Anonymous',
      id: 5,
      network: 'Bitcoin Network',
    }),
  ];

  constructor() {}

  getTransactions(): Observable<any> {
    return of(this.transactions);
  }

  getTransactionDetail(id: number) {
    return of(this.transactions.filter((x: Transaction) => x.id === id)[0]);
  }
}
