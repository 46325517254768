import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.scss'],
})
export class RegistrationFormComponent implements OnInit {
  @Input() height = 70;
  @Output() toggle = new EventEmitter();
  @Output() trigger = new EventEmitter();
  //THE FORM CONTROL
  get primEmail() {
    return this.userEmails.get('primaryEmail');
  }
  title = 'email-validation';
  userEmails = new FormGroup({
    primaryEmail: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    passwordFormControl: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]{8,}$/),
    ]),
    password2FormControl: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[a-zA-Z0-9]{8,}$/),
    ]),
  });
  // VARIABLES FOR THE LOGIN
  textEmailLabel = 'email';
  // email = '';
  emailValid: boolean;

  password = '';
  hasPassword = false;
  inputType = 'password';

  passwordTwo = '';
  has2Password = false;
  inputTypeTwo = 'password';

  signUpObj = {
    email: '',
    password: '',
  };
  //Related to Animations
  isLoading = false;
  text = 'sign-up';
  status: 'Done';
  clicked = false;
  dataReceived = false;
  activateAnimation = true;
  submitDisabled = true;
  warning;
  warningTwo;

  tester;

  constructor(private authService: AuthService) {}

  ngOnInit() {}

  validateEmail(event: any) {
    this.signUpObj.email = event;
    const input = document.querySelector('#email');
    const square = document.getElementsByClassName('square');
    const label = document.querySelector('label');
    if (this.primEmail.errors === null && this.signUpObj.email !== '') {
      // THIS BLOCK SHOWS THE GREEN MESSAGE
      input.classList.remove('invalid');
      input.classList.add('valid');
      label.classList.remove('invalid');
      label.classList.add('valid');
      square[0].classList.remove('invalid');
      square[0].classList.add('valid');
      this.emailValid = true;
    } else if (
      this.primEmail.errors !== null &&
      this.activateAnimation === true
    ) {
      // THIS BLOCK REMOVES GREEN OR RED MESSAGE
      this.emailValid = null;
      input.classList.remove('invalid');
      input.classList.remove('valid');
      label.classList.remove('invalid');
      label.classList.remove('valid');
      square[0].classList.remove('valid');
      square[0].classList.remove('invalid');
    }
    this.checkForm();
  }

  // THIS WILL CHECK ON BLUR TO SEE IF EMAIL IS CORRECT OR NOT
  checkEmail() {
    if (this.primEmail.errors !== null && this.signUpObj.email !== '') {
      const input = document.querySelector('#email');
      const square = document.getElementsByClassName('square');
      const label = document.querySelector('label');
      // THIS BLOCK SHOWS THE RED MESSAGE
      input.classList.remove('valid');
      input.classList.add('invalid');
      label.classList.remove('valid');
      label.classList.add('invalid');
      square[0].classList.add('invalid');
      square[0].classList.remove('valid');
      this.emailValid = false;
    }
  }

  // THIS WILL DELETE THE WHOLE EMAIL FIELD WHEN RED MESSAG APPEARS
  deleteInput() {
    //Activate Animation is critical for toggling the green and red animation
    this.activateAnimation = false;
    if (this.emailValid === false) {
      const input = document.querySelector('#email');
      const square = document.getElementsByClassName('square');
      const label = document.querySelector('label');
      square[0].classList.add('slide');
      setTimeout(() => {
        this.signUpObj.email = '';
        input.classList.remove('valid');
        label.classList.remove('valid');
        square[0].classList.remove('valid');
      }, 100);
      setTimeout(() => {
        square[0].classList.remove('slide');
        square[0].classList.remove('invalid');
        label.classList.remove('invalid');
        input.classList.remove('invalid');
        this.activateAnimation = true;
      }, 500);
    }
  }

  validatePassword(event) {
    this.password = event;
    if (this.password !== '') {
      this.hasPassword = true;
    } else {
      this.hasPassword = false;
    }
    this.checkForm();
  }

  validatePasswordTwo(event) {
    this.passwordTwo = event;
    if (this.passwordTwo !== '') {
      this.has2Password = true;
    } else {
      this.has2Password = false;
    }
    this.checkForm();
  }

  //THE EYE BUTTON TO TOGGLE
  showPassword() {
    if (this.inputType === 'password') {
      this.inputType = 'text';
    } else {
      this.inputType = 'password';
    }
  }
  showPasswordTwo() {
    if (this.inputTypeTwo === 'password') {
      this.inputTypeTwo = 'text';
    } else {
      this.inputTypeTwo = 'password';
    }
  }

  // THIS IS WHERE API CALLS WILL HAPPEN SPECIFICALLY FOR THE BUTTON ANIMATION
  // DEPENDING ON WHAT YOU GET BACK FROM THE API, apply conditional accordingly
  onSubmit() {
    if (this.password !== this.passwordTwo) {
      this.warningTwo = true;
      setTimeout(() => {
        this.warningTwo = false;
      }, 400);
      return;
    }
    this.clicked = false;
    if (this.submitDisabled === false) {
      this.signUpObj.password = this.password;
      // dummy email checking to see if it has the same email
      this.authService.register();

      if (this.signUpObj.email !== 'n@g.com') {
        this.clicked = true;
      } else {
        this.clicked = true;
        this.isLoading = true;
        setTimeout(() => {
          this.text = 'email-already-exists-try-again';
          this.isLoading = false;
          this.clicked = false;
          const input = document.querySelector('#email');
          const square = document.getElementsByClassName('square');
          const label = document.querySelector('label');
          // THIS BLOCK SHOWS THE RED MESSAGE
          input.classList.remove('valid');
          input.classList.add('invalid');
          label.classList.remove('valid');
          label.classList.add('invalid');
          square[0].classList.add('invalid');
          square[0].classList.remove('valid');
          this.emailValid = false;
          setTimeout(() => {
            this.text = 'sign-up';
          }, 2500);
        }, 1000);
      }
    }
    if (this.emailValid === null) {
      this.warning = true;
      setTimeout(() => {
        this.warning = false;
      }, 400);
    }
    if (this.password === '' || null || undefined) {
      this.warningTwo = true;
      setTimeout(() => {
        this.warningTwo = false;
      }, 400);
    }
  }

  checkForm() {
    // THIS WILL CHECK WHETHER PASSWORD WILL HAVE GREEN TOGGLE OR RED
    // if (this.password === this.passwordTwo) {
    //   console.log('check 2');
    //   this.tester = true;
    // }

    if (
      this.hasPassword === true &&
      this.has2Password === true &&
      this.emailValid &&
      this.password === this.passwordTwo
    ) {
      this.submitDisabled = false;
    } else {
      this.submitDisabled = true;
    }
  }
}
