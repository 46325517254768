export class TransactionActivity {
  dateTime: number;
  type: 0 | 1;
  amount: number;
  currency: string;
  name: string;
  id: number;
  network: string;

  constructor(activity: any) {
    this.dateTime = activity.dateTime;
    this.type = activity.type;
    this.amount = activity.amount;
    this.currency = activity.currency;
    this.name = activity.name;
    this.id = activity.id;
    this.network = activity.network;
  }
}
