import { Component, OnInit } from '@angular/core';

declare let TweenLite;
declare let TimelineMax;
declare let Sine;
declare let ntl;
declare let Power0;

@Component({
  selector: 'app-animated-confetti',
  templateUrl: './animated-confetti.component.html',
  styleUrls: ['./animated-confetti.component.scss']
})
export class AnimatedConfettiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.startAnimation();
  }

  getRndInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1) ) + min;
  }

  randomFloatBetween(minValue, maxValue, precision){
    if(typeof(precision) == 'undefined'){
      precision = 2;
    }
    return parseFloat(Math.min(minValue + (Math.random() * (maxValue - minValue)),maxValue).toFixed(precision));
  }

  startAnimation() {
    document.querySelectorAll('svg path, svg g').forEach((el) => {
      el.removeAttribute('data-name');
    });

    document.querySelectorAll('svg [id*="_Group"], svg [id*="_Path"]').forEach((el) => {
      el.removeAttribute('id');
    });

    TweenLite.randomEase = Sine.easeInOut;

    const confetti = new TimelineMax({
      repeat: 0,
      repeatDelay: 0
    });

    confetti
    .to('#confetti #confetti-particles', .5, {opacity: 1});

    document.querySelectorAll('#confetti #confetti-particles rect').forEach((el, index) => {
      const ntl = new TimelineMax({});

      const hor = this.getRndInteger(-400, 400);
      const vert = this.getRndInteger(200, 800);
      const timeEl = this.randomFloatBetween(2, 3, 2);
      const elDur = this.randomFloatBetween(0.8, 1.5, 2);

      const offsetTimeEl = '-=' + timeEl/3;

      ntl
      .to(el, timeEl, {x: hor}, 0)
      .fromTo(el, timeEl/3, {y: 100}, {y: -vert, ease: 'power3.out'}, 0)
      .to(el, timeEl/2, {y: 100, ease: 'power1.in'}, timeEl/3)
      .to(el, .5, {opacity: 0}, offsetTimeEl)
      .to(el, elDur, {transformOrigin: '50% 50%', rotation: Math.random() > .5 ? -360:360, repeat: .5, ease: 'linear'}, 0);
      confetti.add(ntl, 0);
    });



  }
}
