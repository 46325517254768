import { Component, OnInit, Input } from '@angular/core';
import { ForTranslateService } from 'src/app/services/for-translate.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {

  @Input() translation: string;
  @Input() whiteText: boolean;

  languageOptions = this.translateService.languages;

  constructor(
      public translateService: ForTranslateService
    ) { }

  setTranslation() {
    this.translateService.setCurrentLanguage = this.translation;
  }

  ngOnInit() {
    if(!this.translation) {
      this.translation = this.translateService.currentLanguage;
    }
  }

}
