import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { trigger, transition, animate, style, keyframes, query, group } from '@angular/animations';
import { WalletService } from '../../services/wallet.service';
import { ForTranslateService } from '../../services/for-translate.service';

@Component({
  selector: 'app-input-currency-swap',
  templateUrl: './input-currency-swap.component.html',
  styleUrls: ['./input-currency-swap.component.scss'],
  animations: [
    trigger('inRight', [
      transition('void => *', []),   // when the item is created
      transition('* => void', []),   // when the item is removed
      transition('* => *', [         // when the item is changed
        style ({ transform: 'translateX(20px)', opacity: 0 }),
        animate('500ms ease', keyframes([
          style ({ transform: 'translateX(0px)', opacity: 1 }),
        ])),
      ]),
    ]),
    trigger('inLeft', [
      transition('void => *', []),   // when the item is created
      transition('* => void', []),   // when the item is removed
      transition('* => *', [         // when the item is changed
        group([
          query('.unit-one', [
            style ({ transform: 'translate(-20px,0)', opacity: 0 }),
            animate('500ms ease', keyframes([
              style ({ transform: 'translate(0px,0)', opacity: 1 }),
            ])),
          ]),
        ])
      ]),
    ])
  ]
})
export class InputCurrencySwapComponent implements OnInit, OnChanges {

  @Input() unit: string;
  @Input() unit2: string;
  @Input() right = 'auto';
  @Input() top = 'auto';
  @Input() height = '100%';
  @Input() whiteBackground = false;

  amount;
  convertedAmount: number;
  currency: string;
  warning: boolean;
  switched = false;

  constructor(
    public walletService: WalletService,
    public translateService: ForTranslateService,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currency = this.translateService.currentCurrencyObj.currencyCode.toLowerCase();

    if(changes.unit && !changes.unit.firstChange) {
      this.unit2 = this.walletService.selectedCoin.symbol;

      this.switched = this.switched ? false : this.switched;
      this.convertedAmount = this.amount / this.walletService.selectedCoin.conversion[this.currency];
    } else if(changes.unit2 && !changes.unit2.firstChange) {
      this.unit = this.translateService.currentCurrencyObj.currencyCode;
      this.convertedAmount = this.amount / this.walletService.selectedCoin.conversion[this.currency];
    }
  }

  updateAmount(event) {
    if(this.switched) {
      this.amount = event;
      this.convertedAmount = this.amount * this.walletService.selectedCoin.conversion[this.currency];
      this.walletService.selectedCoin.inputAmount = this.amount;
    } else {
      this.amount = event;
      this.convertedAmount = this.amount / this.walletService.selectedCoin.conversion[this.currency];
      this.walletService.selectedCoin.inputAmount = this.convertedAmount;
    }
  }

  onSwap() {
    this.switched = !this.switched;
    const placeholder = this.unit2;
    this.unit2 = this.unit;
    this.unit = placeholder;

    if(!this.switched) {
      const placeholder2 = this.amount;
      this.convertedAmount = this.amount / this.walletService.selectedCoin.conversion[this.currency];
      this.walletService.selectedCoin.inputAmount = this.convertedAmount;
    } else {
      const placeholder2 = this.amount;
      this.convertedAmount = this.amount * this.walletService.selectedCoin.conversion[this.currency];
      this.walletService.selectedCoin.inputAmount = this.amount;
    }
  }
}
