import { Injectable } from '@angular/core';
import { Wallet } from '../models/wallet/wallet';

@Injectable({
  providedIn: 'root'
})
export class AppStoreService {
  //Routing
  previousRoute: string;
  //Shake Screen Counter
  failureCount = 0;
  failureCountMin = 2;
  //Wallets
  wallets: [Wallet, Wallet?] = [new Wallet()];
  activeWallet = 'default';

  constructor() {}
}
