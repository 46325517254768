import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { animate, group, query, state, style, transition, trigger } from '@angular/animations';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-log-reg-box',
  templateUrl: './auth-log-reg-box.component.html',
  styleUrls: ['./auth-log-reg-box.component.scss'],
})
export class AuthLogRegBoxComponent implements OnInit {

  @Input() width = 'auto';
  @Input() title = 'welcome-to-mighty';
  @Input() authPage = true;

  error: string;
  status = false;
  error2: string;

  loginCode;
  loginForm = true;

  shakeLogin = false;
  constructor(
    public router: Router,
    public authService: AuthService
  ) {}

  ngOnInit() {}

  login() {
    this.status = true;
    this.authService.isLoggedIn = true;
    if(this.authPage) {
      this.router.navigate(['/home']);
    }
  }

  triggerShake() {
    setTimeout(() => {
      this.shakeLogin = true;
      setTimeout(() => {
        this.shakeLogin = false;
      }, 500);
    }, 500);
  }
}
